import { template as template_a4fde6342c504af092ace9b143ee7c01 } from "@ember/template-compiler";
const FKText = template_a4fde6342c504af092ace9b143ee7c01(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;

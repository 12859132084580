import { template as template_2f73600f9ca7459bad28f212550f38cd } from "@ember/template-compiler";
const FKControlMenuContainer = template_2f73600f9ca7459bad28f212550f38cd(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

import { template as template_fe89954e7f1c4096a0dd5cd5e0068a65 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_fe89954e7f1c4096a0dd5cd5e0068a65(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
